
// import IndexHtml from '@src/Allcomponents/IndexHtml'

const defaultApp = [
    {
    path: '/',
    name: '首页',
    redirect:'/echarts-4'  //重定向path
   },
   {
    path: '/echarts-1',
    name: 'echarts-1',
    meta: {
        title: '',
    },
    component: () =>
        import('../components/echarts-1.vue')
   },
   {
    path: '/echarts-2',
    name: 'echarts-2',
    meta: {
        title: '',
    },
    component: () =>
        import('../components/echarts-2.vue')
   },
   {
    path: '/echarts-3',
    name: 'echarts-3',
    meta: {
        title: '',
    },
    component: () =>
        import('../components/echarts-3.vue')
   },
   {
    path: '/echarts-4',
    name: 'echarts-4',
    meta: {
        title: '',
    },
    component: () =>
        import('../components/echarts-4.vue')
   },
]
export default [
    ...defaultApp,

]