import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        textNum:0,
       tableSrollLeft:0
    },
    mutations: {
        textNum(state,code){
            state.textNum=code
        },
       
    },
    actions: {
        //
    },
    modules: {
        
    }
})