import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
Vue.use(Router)
const router = new Router({
    // mode:"history",//使用历史模式，去除#
    routes,
    scrollBehavior(to, from, savedPosition) {//滚动到原来的位置，仅支持路由history模式
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
        // return 期望滚动到哪个的位置
    }
})
router.beforeEach((to, from, next) => {
    next(
        {

        }
    )


})

export default router

