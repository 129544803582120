import Vue from 'vue'
import App from './App.vue'
import store from './store';
import VueI18n from 'vue-i18n'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/iconfont/iconfont.css'
import router from './router';
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

Vue.use(ViewUI);
Vue.use(VueI18n) // 通过插件的形式挂载
Vue.use(ElementUI);
 
const i18n = new VueI18n({
  locale:sessionStorage.getItem('language')||'zh-CN',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./assets/common/js/lang/zh'),   // 中文语言包
    'en-US': require('./assets/common/js/lang/en')    // 英文语言包
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
