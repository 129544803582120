<template>
  <div>
    <div>
      <Button type="primary" @click="getLang('zh-CN')">中文</Button>
      <Button type="primary" @click="getLang('en-US')">English</Button>
      <div>{{ $t("lang.btn") }}</div>
           <button @click="getLink">跳转</button>

    </div>
    <component :is="com"></component>
    <div id="icon"></div>

    <div id="container"></div>

  </div>
</template>

<script>
import { Graph, Shape, Model } from "@antv/x6";
import { GridLayout, DagreLayout, ForceLayout } from "@antv/layout";
// import child from './childPage.vue'
export default {
  name: "HelloWorld",
  data() {
    return {
      Xdata: [
        {
          relationships: [
            {
              startNode: 3926429,
              type: "INVEST",
              endNode: "3926432",
              properties: {
                isRed: 0,
                percent: "0.98",
              },
            },
            {
              startNode: 3204930691,
              type: "INVEST",
              endNode: "3271083514",
              properties: {
                isRed: 0,
                percent: "0.9987999999999999",
              },
            },
            {
              startNode: 3271083514,
              type: "INVEST",
              endNode: "2976804018",
              properties: {
                isRed: 0,
                percent: "0.0296",
              },
            },
            {
              startNode: 286060994,
              type: "INVEST",
              endNode: "19268199",
              properties: {
                isRed: 0,
                percent: "0.1222",
              },
            },
            {
              startNode: 2339245305,
              type: "INVEST",
              endNode: "3204930691",
              properties: {
                isRed: 0,
                percent: "0.2379",
              },
            },
            {
              startNode: 2976804018,
              type: "INVEST",
              endNode: "3450968886",
              properties: {
                isRed: 1,
                percent: "1.0",
              },
            },
            {
              startNode: 11944211,
              type: "INVEST",
              endNode: "286060994",
              properties: {
                isRed: 0,
                percent: "0.8",
              },
            },
            {
              startNode: 3926429,
              type: "INVEST",
              endNode: "3926416",
              properties: {
                isRed: 1,
                percent: "0.94",
              },
            },
            {
              startNode: 73098710,
              type: "INVEST",
              endNode: "11944211",
              properties: {
                isRed: 0,
                percent: "1.0",
              },
            },
            {
              startNode: 3926416,
              type: "INVEST",
              endNode: "19268199",
              properties: {
                isRed: 1,
                percent: "0.6208",
              },
            },
            {
              startNode: 3926416,
              type: "INVEST",
              endNode: "73098710",
              properties: {
                isRed: 0,
                percent: "1.0",
              },
            },
            {
              startNode: 19268199,
              type: "INVEST",
              endNode: "26615386",
              properties: {
                isRed: 1,
                percent: "1.0",
              },
            },
            {
              startNode: 1925829660,
              type: "INVEST",
              endNode: "3926429",
              properties: {
                isRed: 1,
                percent: "0.7509999999999999",
              },
            },
            {
              startNode: 184329431,
              type: "INVEST",
              endNode: "2339245305",
              properties: {
                isRed: 1,
                percent: "1.0",
              },
            },
            {
              startNode: 3926432,
              type: "INVEST",
              endNode: "3926416",
              properties: {
                isRed: 0,
                percent: "0.06",
              },
            },
            {
              startNode: 73098710,
              type: "INVEST",
              endNode: "286060994",
              properties: {
                isRed: 0,
                percent: "0.2",
              },
            },
            {
              startNode: 2339245305,
              type: "INVEST",
              endNode: "2976804018",
              properties: {
                isRed: 1,
                percent: "0.9704",
              },
            },
            {
              startNode: 26615386,
              type: "INVEST",
              endNode: "184329431",
              properties: {
                isRed: 1,
                percent: "1.0",
              },
            },
          ],
          nodes: [
            {
              id: "3271083514",
              properties: {
                gid: "3271083514",
                name: "运城信恒翔壹号股权投资合伙企业（有限合伙）",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "184329431",
              properties: {
                gid: "184329431",
                name: "吉林建龙钢铁有限责任公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "19268199",
              properties: {
                gid: "19268199",
                name: "天津建龙钢铁实业有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "26615386",
              properties: {
                gid: "26615386",
                name: "浙江建龙钢铁实业有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "73098710",
              properties: {
                gid: "73098710",
                name: "浙江建龙控股集团有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "3926429",
              properties: {
                gid: "3926429",
                name: "北京建龙投资有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "2339245305",
              properties: {
                gid: "2339245305",
                name: "山西建龙钢铁有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "286060994",
              properties: {
                gid: "286060994",
                name: "上海钧能实业有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "3926432",
              properties: {
                gid: "3926432",
                name: "北京山水永明投资有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "2976804018",
              properties: {
                gid: "2976804018",
                name: "山西建龙实业有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "3926416",
              properties: {
                gid: "3926416",
                name: "北京建龙重工集团有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "1925829660",
              properties: {
                gid: "",
                name: "张志祥",
                type: 1,
              },
              labels: ["Human"],
            },
            {
              id: "11944211",
              properties: {
                gid: "11944211",
                name: "浙江建龙物产有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "3204930691",
              properties: {
                gid: "3204930691",
                name: "山西信恒翔股权投资合伙企业（有限合伙）",
                type: 2,
              },
              labels: ["Company"],
            },
            {
              id: "3450968886",
              properties: {
                gid: "3450968886",
                name: "吕梁建龙实业有限公司",
                type: 2,
              },
              labels: ["Company"],
            },
          ],
        },
      ],
      com: "",
  
    };
  },
  components: {
    child1: () => ({
      component: import("./childPage.vue"),
    }),
    child2: () => ({
      component: import("./childPage2.vue"),
    }),
  },
  props: {
    msg: String,
  },
  mounted() {
    console.log('  this.$i18n.locale',  this.$i18n.locale);

    const data = {
      nodes: [],
      // 边
      edges: [],
    };
    this.Xdata[0].nodes.map((item) => {
      // item['ntype'] = this.getNtype(item['labels'], item['properties'], this.targetCompany),
      data.nodes.push({
        id: item.id.toString(),
        shape: this.getshape(item.properties.type), // 使用 ellipse 渲染
        width: 120, // Number，可选，节点大小的 width 值
        height: 50, // Number，可选，节点大小的 height 值
        zIndex: 2,

        attrs: {
          body: {
            fill: "#fff",
            stroke: "rgb(255,69,91)", // 边框颜色
          },
          label: {
            fill: "#000",
            fontSize: 12,
            lineHeight: 16,
          },
          text: {
            textWrap: {
              text: item.properties.name,
              width: -20, // 宽度减少 10px
            },
          },
        },
      });
    });

    // this.Xdata.map(v=>{
    this.Xdata[0].relationships.map((item) => {
      data.edges.push({
        source: item.startNode.toString(),
        target: item.endNode.toString(),
        attrs: {
          line: {
            stroke: "#fd6d6f",
            strokeWidth: 1,
          },
        },
        labels: [
          {
            attrs: {
              label: { text: Number(item.properties.percent) * 100 + "%" },
            },
          },
        ],
      });
    });
    const graph = new Graph({
      container: document.getElementById("container"),
      panning: true,
      mousewheel: true,
      grid: true,
    });
    //力导图
    // const forceLayout = new ForceLayout({
    //   type: 'force',
    //   center: [500, 360],
    //   preventOverlap: true,
    //   linkDistance: (d) => {

    //     return 100
    //   },
    //   nodeStrength: (d) => {
    //     return -1500
    //   },
    //   edgeStrength: (d) => {

    //     return 0.1
    //   },
    //   tick: () => {
    //     const model = data
    //     graph.fromJSON(model)
    //   },
    // })
    // console.log('data', data)

    // forceLayout.layout(data)

    const dagreLayout = new DagreLayout({
      type: "dagre",
      rankdir: "TB",
      align: "UL",
      ranksep: 35,
      nodesep: 15,
    });
    const model = dagreLayout.layout(data);

    graph.fromJSON(model);
  },
  methods: {
    getLang(value) {
      this.$i18n.locale = value; //关键语句
      sessionStorage.setItem('language',value)
      this.$store.commit("textNum", 1);
      localStorage.setItem("textNum", 1);
      if (value == "zh-CN") this.com = "child1";
      else this.com = "child2";
    },
    getLink(){
      window.open('http://localhost:8080/')
    },
    // 判断是否节点类型, 0:目标公司 1:目标企业 2:非目标企业 3：人员
    getshape(type) {
      if (type == "1") {
        return "circle";
      } else {
        return "rect";
      }
    },
    listenMounted() {
      console.log(1112);
    },
  },
  // mounted() {
  //   console.log("graph", Graph);
  //   const data = {
  //     nodes: [
  //       {
  //         id: "node1", // String，可选，节点的唯一标识
  //         shape: "ellipse", // 使用 ellipse 渲染
  //         x: 40, // Number，必选，节点位置的 x 值
  //         y: 40, // Number，必选，节点位置的 y 值
  //         width: 80, // Number，可选，节点大小的 width 值
  //         height: 40, // Number，可选，节点大小的 height 值
  //         zIndex: 2,
  //         parent: true,
  //         attrs: {
  //           body: {
  //             fill: "#2ECC71",
  //             stroke: "#000",
  //             // strokeDasharray: "10,2",
  //           },
  //           label: {
  //             text: "Hello",
  //             fill: "#333",
  //             fontSize: 13,
  //           },
  //         },
  //         ports: {
  //           groups: {
  //             group1: {
  //               attrs: {
  //                 circle: {
  //                   r: 6,
  //                   magnet: true,
  //                   stroke: "#31d0c6",
  //                   strokeWidth: 1,
  //                   fill: "#fff",
  //                 },
  //               },
  //             },
  //             group2: {
  //               attrs: {
  //                 circle: {
  //                   r: 6,
  //                   magnet: false,
  //                   stroke: "#31d0c6",
  //                   strokeWidth: 2,
  //                   fill: "#fff",
  //                 },
  //               },
  //             },
  //           },
  //           items: [
  //             {
  //               id: "port1",
  //               group: "group2",
  //               attrs: {
  //                 text: {
  //                   // 标签选择器
  //                   // text: "port2", // 标签文本
  //                 },
  //               },
  //             },
  //           ],
  //         },
  //       },
  //       {
  //         id: "node2", // String，节点的唯一标识
  //         x: 160, // Number，必选，节点位置的 x 值
  //         y: 180, // Number，必选，节点位置的 y 值
  //         width: 80, // Number，可选，节点大小的 width 值
  //         height: 40, // Number，可选，节点大小的 height 值
  //         label: "node2", // String，节点标签
  //         zIndex: 2,
  //         ports: {
  //           groups: {
  //             group1: {
  //               attrs: {
  //                 circle: {
  //                   r: 6,
  //                   magnet: true,
  //                   stroke: "#31d0c6",
  //                   strokeWidth: 1,
  //                   fill: "#fff",
  //                 },
  //               },
  //             },
  //           },
  //           items: [
  //             {
  //               id: "port2",
  //               group: "group1",
  //               attrs: {
  //                 text: {
  //                   // 标签选择器
  //                   // text: "port2", // 标签文本
  //                 },
  //               },
  //             },
  //           ],
  //         },
  //       },
  //       ,
  //       {
  //         id: "node3", // String，节点的唯一标识
  //         x: 360, // Number，必选，节点位置的 x 值
  //         y: 240, // Number，必选，节点位置的 y 值
  //         width: 80, // Number，可选，节点大小的 width 值
  //         height: 40, // Number，可选，节点大小的 height 值
  //         zIndex: 2,
  //         attrs: {
  //           body: {
  //             fill: "#2ECC71",
  //             stroke: "#000",

  //             // strokeDasharray: "10,2",
  //           },
  //           label: {
  //             text: "node3",
  //             fill: "#333",
  //             fontSize: 13,
  //           },
  //         },
  //       },
  //       {
  //         id: "node4", // String，节点的唯一标识
  //         x: 160, // Number，必选，节点位置的 x 值
  //         y: 300, // Number，必选，节点位置的 y 值
  //         width: 80, // Number，可选，节点大小的 width 值
  //         height: 40, // Number，可选，节点大小的 height 值
  //         label: "node4", // String，节点标签
  //         zIndex: 2,
  //       },
  //       {
  //         id: "node5", // String，节点的唯一标识
  //         x: 160, // Number，必选，节点位置的 x 值
  //         y: 360, // Number，必选，节点位置的 y 值
  //         width: 80, // Number，可选，节点大小的 width 值
  //         height: 40, // Number，可选，节点大小的 height 值
  //         label: "node5", // String，节点标签
  //         zIndex: 2,
  //       },
  //       {
  //         id: "node6", // String，节点的唯一标识
  //         x: 160, // Number，必选，节点位置的 x 值
  //         y: 420, // Number，必选，节点位置的 y 值
  //         width: 80, // Number，可选，节点大小的 width 值
  //         height: 40, // Number，可选，节点大小的 height 值
  //         label: "node6", // String，节点标签
  //         zIndex: 2,
  //       },
  //       ,
  //       {
  //         id: "node7", // String，节点的唯一标识
  //         x: 160, // Number，必选，节点位置的 x 值
  //         y: 480, // Number，必选，节点位置的 y 值
  //         width: 80, // Number，可选，节点大小的 width 值
  //         height: 40, // Number，可选，节点大小的 height 值
  //         label: "node7", // String，节点标签
  //         zIndex: 2,
  //       },
  //     ],
  //     // 边
  //     edges: [
  //       {
  //         source: {
  //           cell: "node1",   //必须，目标节点名称
  //           port: "port1", // 链接桩 ID
  //         },
  //         target: {
  //           cell: "node2",   //必须，目标节点名称
  //           port: "port2", // 链接桩 ID
  //         },
  //         labels: [
  //           {
  //             attrs: { label: { text: "75%" } },
  //           },
  //         ],
  //       },
  //       {
  //         source: "node2", // String，必须，起始节点 id
  //         target: "node3", // String，必须，目标节点 id
  //       },
  //       {
  //         source: "node2", // String，必须，起始节点 id
  //         target: "node4", // String，必须，目标节点 id
  //       },
  //       {
  //         source: "node3", // String，必须，起始节点 id
  //         target: "node4", // String，必须，目标节点 id
  //       },
  //       {
  //         source: "node3", // String，必须，起始节点 id
  //         target: "node6", // String，必须，目标节点 id
  //       },
  //       {
  //         source: "node4", // String，必须，起始节点 id
  //         target: "node5", // String，必须，目标节点 id
  //       },
  //       {
  //         source: "node5", // String，必须，起始节点 id
  //         target: "node6", // String，必须，目标节点 id
  //       },
  //       {
  //         source: "node6", // String，必须，起始节点 id
  //         target: "node7", // String，必须，目标节点 id
  //       },
  //     ],
  //   };

  //   const graph = new Graph({
  //     container: document.getElementById("container"),
  //     panning:true,
  //     mousewheel: true,
  //   });
  //   console.log("graphgraphgraph", Shape);

  //   graph.fromJSON(data);
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
#container {
  width: 100%;
  height: 1600px;
  margin: 0 auto;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#icon {
  width: 0;
  height: 0;
  border: 15px solid #fff;
  border-bottom-color: red;
}
</style>
